import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter-to-line.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="TwitterのフォロワーをLINE友達にする"
		  desc="TwitterのフォローフォロワーをLINE友達にする方法です。LINE友達になることで1対1のコミュニケーションができエンゲージメントを高めることができます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/to-line'}
		  type={'Article'}
		  shortName="TwitterのフォロワーをLINE友達にする事例・サンプル"
		  createdAt="2021-11-26"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="TwitterのフォロワーをLINE友達にする"
		homePageText="Home"
		homePageUrl="/"
		activePageText="TwitterのフォロワーをLINE友達にする"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/twitter',
		  name: 'Twitterキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"TwitterのフォロワーをLINE友達にする"}
		description={"TwitterのフォローフォロワーをLINE友達にする方法です。LINE友達になることで1対1のコミュニケーションができエンゲージメントを高めることができます。"}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS, Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.GET_FRIENDS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.CONNECT_LINE_BUSINESS, Constants.CAMPAIGN_EFFECTS.INCREASED_ENGAGEMENT, Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE]}
		plan={'ベーシックプラン以上'}
		promotionId="01b1a7704d9ba79f87"
		platformExample={
		  <PlatformExample
			title="その他のTwitterキャンペーン"
			types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag','twitterToLine','twitterInstantWin','twitterMultipleAccounts']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples